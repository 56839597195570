<template>
  <el-dialog append-to-body
    :visible.sync="dialogVisible"
    width="720px"
    custom-class="payment_dialog"
    @close="handleClose"
    :show-close="closeable"
    :close-on-click-modal="false"
    :close-on-press-escape="false">
      <div v-if="currentStep===0" class="payment_step">
        <div class="payment_product_list">
          <!-- <vxe-table
            ref="table"
            border
            row-id="id"
            size="mini"
            resizable
            align="center"
            :data="productList"
            @radio-change="handleProductSelected"
            max-height="200">
             <vxe-column type="radio" title="请选择" width="100"></vxe-column>
             <vxe-column show-overflow field="name" :title="productNameType  === 'service' ? '服务商' : '服务医院'" width="260"></vxe-column>
             <vxe-column field="indate" title="到期日期" width="100"></vxe-column>
          </vxe-table> -->
          <div>商品名称 医修保基础版</div>
          <div>商品价格 ￥5000</div>
        </div>
        <div class="payment_product_policy">
          <el-checkbox v-model="isAgreePolicy"></el-checkbox>
          <span>同意并接受<a v-for="a in agreementList" class="text-primary" :key="a.title" :href="a.href" target="_blank">{{a.title}}</a></span>
        </div>
        <el-button type="primary"
          :loading="isCreatingOrder"
          @click="createOrder"
          :disabled="!isAgreePolicy"
          >立即支付</el-button>
          <!--           :disabled="!isAgreePolicy || !selectedProductId || !productList.length" -->
        <div class="text-align-center w-100" v-if="isExpiryed">
          <span class="text-disable font-small">您的租期已到期，若有疑问，请联系李经理16601886016</span>
        </div>
      </div>
      <div  v-else-if="currentStep===1" >
        <i @click="backToStep1"  class="el-icon-arrow-left cursor-pointer font-weight-light mb-3">
          <span class="ml-1">服务选择</span>
        </i>
        <div class="payment_scan" >
          <div class="payment_selection">
            <div @click="handlePayTypeClick('2')" :class="{selected:payType==='2', wx: true}">
              <i class="iconfont  icon-wechat"><span class="ml-1">微信支付</span></i>
            </div>
            <!-- <div @click="handlePayTypeClick('1')" :class="{selected:payType==='1', zfb: true}">
              <i class="iconfont  icon-alipay"> <span class="ml-1">支付宝</span></i>
            </div> -->
            <div @click="handlePayTypeClick('3')" :class="{selected:payType==='3'}">
              <i class="iconfont  icon-zhuanzhang"> <span class="ml-1">银行汇款</span></i>
            </div>
          </div>
          <div v-if="payType !== '3'" class="payment_qr">
            <div class="qr_content">
              <img :src="payQRCode" alt="" height="108" width="108">
              使用{{ payType === '1' ? '支付宝': '微信' }}扫描二维码
            </div>
            <div>
              <div>
                <span>商品名称</span>
                <!-- <span>{{currentOrder.systemName}}</span> -->
                <span>医修保基础版</span>
              </div>
              <div>
                <span>商品价格</span>
                <span>￥{{currentOrder.totalAmount}}</span>
              </div>
              <!-- <div>
                <span>{{productNameType  === 'service' ? '服务商' : '服务医院'}}</span>
                <span>{{productNameType  === 'service' ? currentOrder.buyerName : currentOrder.objName }}</span>
              </div> -->
            </div>
          </div>
          <div v-else>
            <div class="bank">
              <el-descriptions class="margin-top" :column="2" border>
                <el-descriptions-item>
                  <template slot="label">
                    收款银行账户名称：
                  </template>
                  {{ collectionInfo.collectionAccoutName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    收款银行账号：
                  </template>
                  {{ collectionInfo.collectionAccout }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    收款银行：
                  </template>
                  {{ collectionInfo.collectionBank }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    打款金额：
                  </template>
                  ￥{{ collectionInfo.collectionNum }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    备注信息
                  </template>
                  {{ collectionInfo.remark }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    汇款账户名称：
                  </template>
                  {{ collectionInfo.transferAccountName }}
                </el-descriptions-item>
              </el-descriptions>
              <strong style="color: #ff0000;display: inline-block; padding: .5em 0;">特别提醒</strong>
              <p>建议您使用微信支付，若您需要使用银行汇款，请注意以下事项：</p>
              <ol style="padding-left: 1em;">
                <li>请认真核对收款银行账户信息，并按照上述提示信息进行汇款；</li>
                <li>完成汇款后，需要上传银行回单，我方将在5个工作日内完成审核；</li>
                <li>实际支付时间，将以审核通过时间为准；</li>
                <li>若您已完成汇款，请在下方上传银行回单。</li>
              </ol>
            </div>
            <div class="bank">
                <b style="margin-bottom: .5em; display: block;">*上传回单：</b>
                <Upload
                  v-model="fileLists"
                  label="回单"
                  type="single"
                  accept="image/png,image/jpeg,image/jpg,application/pdf"
                  @success="handleFileLists"
                  @remove="handleRemove"
                  :maxSize="5"
                  size="origin"
                  :limit="1"/>
                  <div style="text-align: right;">
                    <el-button type="primary" :disabled="!fileLists.length" @click="handleUpload">提交</el-button>
                  </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="currentStep===2" class="payment_step payment_success">
        <i class="el-icon-success"></i>
        <span>恭喜您，支付成功</span>
        <el-button type="primary"
          @click="handleSuccess">
          确认
        </el-button>
      </div>
  </el-dialog>
</template>
<script>
import { getProductList, createPaymentOrder, checkIfPaySuccessed, getPayQRCode, changePayMethod, uploadPayVoucher, getServiceProvider } from '@/api/payment';
import Upload from '@/components/common/upload';

export default {
  name: 'pay_dialog',
  props: {
    closeable: Boolean,
    value: Boolean,
    isExpiryed: Boolean,
    defaultCheckedId: {
      type: String,
    },
    defaultCheckedName: {
      type: String,
    },
    productNameType: {
      type: String,
      default: 'hospitals',
    },
  },
  components: {
    Upload,
  },
  data () {
    return {
      dialogVisible: this.value,
      currentStep: 0,
      currentOrder: null,
      productList: [],
      selectedProductId: null,
      selectedProductName: null,
      objId: '',
      objName: '',
      isAgreePolicy: false,
      isCreatingOrder: false,
      payType: '2',
      payQRCode: '',
      agreementList: [
        {
          title: '《服务条款》',
          href: `${process.env.VUE_APP_HOST}xzfwtk.html`,
        },
        {
          title: '《隐私协议》',
          href: `${process.env.VUE_APP_HOST}xzysxy.html`,
        },
      ],
      collectionInfo: {
        collectionAccoutName: '海南小紫医疗科技有限公司',
        collectionAccout: '46189999101300142336',
        collectionBank: '交通银行股份有限公司海口海府路支行',
        collectionNum: '',
        remark: '',
        transferAccountName: '',
      },
      fileLists: [],
    };
  },
  watch: {
    defaultCheckedId () {
      if (this.defaultCheckedId) {
        this.selectedProductId = 2;
        this.objId = this.defaultCheckedId;
        this.objName = this.defaultCheckedName;
      } else {
        this.selectedProductId = null;
        this.selectedProductName = null;
        this.objId = '';
        this.objName = '';
      }
    },
    value () {
      this.dialogVisible = this.value;
      if (this.value) {
        this.getProductList();
      }
    },
    dialogVisible () {
      if (this.dialogVisible !== this.value) {
        this.$emit('input', this.dialogVisible);
      }
      // 关闭对话框，重置
      if (!this.dialogVisible) {
        this.currentStep = 0;
        this.currentOrder = null;
        this.collectionInfo.transferAccountName = '';
        this.collectionInfo.collectionNum = '';
        this.fileLists = [];
        this.isAgreePolicy = false;
        this.isCreatingOrder = false;
        this.payType = '2';
        this.payQRCode = '';
        this.selectedProductId = null;
        this.selectedProductName = null;
        this.objId = '';
        this.objName = '';
        this.clearPollingQuery();
      } else if (this.defaultCheckedId) {
        this.selectedProductId = 2;
        this.objId = this.defaultCheckedId;
        this.objName = this.defaultCheckedName;
      } else {
        this.selectedProductId = null;
        this.selectedProductName = null;
        this.objId = '';
        this.objName = '';
      }
    },
  },
  methods: {
    getProductList () {
      if (this.productNameType === 'service') {
        getServiceProvider({accurateName: this.defaultCheckedName}).then((res) => {
          this.productList = (res.body?.list || []).map(t=>({
            id: t.id,
            name: t.name,
            indate: t.indate ? this.$options.filters['date-format'](t.indate, 'YYYY-MM-DD') : '--',
            objId: t.id,
            objName: t.name,
            productId: 10000,
          }));
          if (this.objId) {
            this.$nextTick(()=>{
              let row = this.$refs.table.getRowById(this.objId);
              this.$refs.table.setRadioRow(row);
            });
          }
        });
        return;
      }
      getProductList().then(res => {
        this.productList = (res.body?.pages || []).map(t=>({
          id: t.id,
          name: t.name,
          indate: t.indate ? this.$options.filters['date-format'](t.indate, 'YYYY-MM-DD') : '--',
          productId: t.productEnum,
          objId: t.productEnum === '2' ? t.id : null,
          objName: t.productEnum === '2' ? t.name : null,
        }));
        if (this.objId) {
          this.$nextTick(()=>{
            let row = this.$refs.table.getRowById(this.objId);
            this.$refs.table.setRadioRow(row);
          });
        }
      });
    },
    handleProductSelected ({row: product}) {
      this.selectedProductId = product.productId;
      this.selectedProductName = product.name;
      this.objId = product.objId;
      this.objName = product.objName;
    },
    createOrder () {
      if (this.isCreatingOrder) {
        return;
      }
      this.isCreatingOrder = true;
      // if (this.selectedProductId) {
      createPaymentOrder({
        productId: '3', // 固定3
        // productName: this.selectedProductName,
        // objId: this.objId,
        // objName: this.objName,
        orderType: '2', // 固定2
      }).then(res => {
        this.currentOrder = res.body;
        const tenantName = JSON.parse(localStorage.userInfo)?.tenantName;
        this.collectionInfo.transferAccountName = tenantName;
        this.collectionInfo.collectionNum = this.currentOrder.totalAmount;
        this.collectionInfo.remark = this.currentOrder.code;
        this.fileLists = [];
        this.isCreatingOrder = false;
        this.currentStep = 1;
        this.handlePayTypeClick();
        this.pollingQueryPayStatus();
      }, ()=>{
        this.isCreatingOrder = false;
      });
      // }
    },
    backToStep1 () {
      this.currentStep = 0;
      this.clearPollingQuery();
      this.payType = '2';
      this.payQRCode = '';
      this.$nextTick(()=>{
        let row = this.$refs.table.getRowById(this.objId);
        this.$refs.table.setRadioRow(row);
      });
    },
    handlePayTypeClick (payType = '2') {
      this.payType = payType;

      if (payType !== '3') {
        getPayQRCode({id: this.currentOrder.id, payMethod: this.payType}).then(res=>{
          this.payQRCode = res.body.qrCode;
        }, ()=>{
          this.payQRCode = '';
        });
      } else {
        changePayMethod({id: this.currentOrder.id, payMethod: this.payType});
      }
    },
    pollingQueryPayStatus () {
      this.timer = setInterval(()=>{
        checkIfPaySuccessed(this.currentOrder.id).then(()=>{
          this.clearPollingQuery();
          this.currentStep = 2;
        });
      }, 2000);
    },
    clearPollingQuery () {
      clearInterval(this.timer);
    },
    handleSuccess () {
      this.$emit('success');
      // this.$tabs.refresh({force: true});
      // this.dialogVisible = false;
      window.location.reload();
    },
    handleClose () {
      if (this.closeable) {
        this.dialogVisible = false;
        this.$emit('close');
      }
    },
    handleFileLists (file) {
      this.fileLists.push(file);
    },
    handleRemove () {
      this.fileLists = [];
    },
    async handleUpload () {
      const params = {
        id: this.currentOrder.id,
        fileName: this.fileLists[0].attachName,
        fileUrl: this.fileLists[0].url,
      };

      await uploadPayVoucher(params);

      this.$message.success('已成功上传银行回单，请等待审核！');
      this.dialogVisible = false;
      this.$emit('close');

      if (this.$eventBus._events.systemSettingAccountList) {
        this.$eventBus.$emit('systemSettingAccountList');
      }
    },
  },
  beforeDestroy () {
    this.clearPollingQuery();
  },
};
</script>
<style lang="scss" >

.payment_dialog {

  &>.el-dialog__header{
    border-bottom: none;
  }

.payment_step {
  display: flex;
  flex-direction: column;
  align-items: center;

  .payment_product_list {
    // display: flex;
    // flex-wrap: nowrap;
    // justify-content: center;
    overflow-y: auto;
    // width: 100%;

    .product_item{
      text-align: center;
      cursor: pointer;

      &.selected{
        &>div:first-child,&>div:last-child {
          border-width: 2px;
          border-color: rgba(182, 126, 30, 0.4);
        }
      }

      &+.product_item {
        margin-left: 24px;
      }

      &>div:first-child{
        height: 46px;
        width: 186px;
        background: #FDE4CB;
        font-size: 18px;
        font-weight: 500;
        color: #B67000;
        line-height: 46px;
        border: 2px solid #FDE4CB;
        border-bottom: none;
        box-sizing: border-box;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &>div:last-child {
        width: 186px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(134, 43, 0, 0.1);
        border: 1px solid #FDE4CB;
        border-top: none;
        font-size: 28px;
        font-weight: 600;
        color: #ED5F00;
        line-height: 60px;
        height: 60px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .payment_product_policy {
    height: 16px;
    margin-top: 32px;
    margin-bottom:12px ;

    span{
      margin-left: 12px;
    }
  }

  button{
    width: 280px;
    margin-bottom: 32px;
  }
}

.payment_scan {
  width: 500px;
  margin: 0 auto 50px;

  .payment_selection {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &>div{
      width: 140px;
      height: 50px;
      border:2px solid #f1f2f5;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &+div{
        margin-left: 24px;
      }

      &.wx {
        color: #07c160;
        &:hover{
          border-color: #07c160;
        }
      }
      &.zfb {
        color: rgb(14, 120, 255);
        &:hover{
          border-color:  rgb(14, 120, 255);
        }
      }

      &.selected.wx {
        background: #07c160;
        box-shadow: 0 2px 6px #07c160;
        border-color: #07c160;
        color: white;
      }

      &.selected.zfb {
        background:rgb(14, 120, 255);
        box-shadow: 0 2px 6px rgb(14, 120, 255);
        border-color:rgb(14, 120, 255);
        color: white;
      }

      &:last-child {
        color: #d81e06;
        &:hover{
          border-color: #d81e06;
        }
      }

      &.selected:last-child {
        background: #d81e06 ;
        box-shadow: 0 2px 6px #d81e06;
        border-color: #d81e06;
        color: white;
      }
    }
  }

  .bank {
    padding: 24px;
    box-shadow: 0px 2px 8px 0px rgba(36, 40, 50, 0.14);
    border-radius: 4px;
    border: 1px solid rgba(113, 121, 141, 0.16);
  }

  .payment_qr {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(36, 40, 50, 0.14);
    border-radius: 4px;
    border: 1px solid rgba(113, 121, 141, 0.16);
    width: 500px;

    .qr_content {
      display: flex;
      align-items: center;
      margin: 24px;
    }

    &>div{
      display: flex;
      flex-direction: column;
      justify-content: center;

      &>div{
        line-height: 32px;

        &>span {
          color: #242832;

          &:first-child{
            width: 4em;
            color: rgba(31, 50, 97, 0.7);
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.payment_success {
  i{
    color: $primary-color;
    font-size: 120px;
  }

  >span{
    font-size: 20px;
    font-weight: 400;
    color: #35373A;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
}

</style>
