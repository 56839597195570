<template>
  <div>
    <p v-if="showAdditive && personCompany.tenantId" class="xz-company-tip" style="padding-top: 0;">个人版</p>
    <div v-if="showAdditive && personCompany.tenantId" @click="switchCurrentCompany(personCompany)" class="xz-company">
      <div class="company-name">
        <el-avatar :size="40" icon="el-icon-user-solid"></el-avatar>
        <div class="name" style="margin-left: 5px;">个人用户</div>
      </div>
      <img v-if="personCompany.isCurrentTenant" :src="require('@/assets/icon_choosed.svg')" />
    </div>
    <p class="xz-company-tip">企业版</p>
    <div class="xz-company-list" :style="{'max-height': maxHeight + 'px'}">
      <div @click="switchCurrentCompany(item)" v-for="(item, index) in dataList" :key="'comp-'+index" class="xz-company">
        <div class="company-name">
          <span class="head">{{ item.tenantName ? item.tenantName.substr(0,1) : '' }}</span>
          <div class="name">
            {{ item.tenantName }}
            <img :src="filterIdentifyStatus(item.authenticationStatus)" />
          </div>
        </div>
        <img v-if="item.isCurrentTenant" :src="require('@/assets/icon_choosed.svg')" />
      </div>
    </div>
    <div v-if="showAdditive" @click="dialogVisible = true" class="xz-company-create">
      <img :src="require('@/assets/icon_qiye.svg')" />
      <span>创建企业/组织</span>
    </div>
    <!-- 创建企业/组织 -->
    <el-dialog title="创建企业/组织" :visible.sync="dialogVisible" :modal-append-to-body="false" :append-to-body="true" width="600px">
      <div class="create-company-tip">注册新企业或者组织，建议进行企业认证，可享受小紫平台数字普惠计划</div>
      <el-form :model="addCompanyForm" :rules="addCompanyFormRules" label-position="top" ref="addCompanyForm" style="padding: 0 20px;">
        <el-form-item label="企业名称" prop="company">
          <el-input placeholder="请输入" v-model="addCompanyForm.company"></el-input>
        </el-form-item>
        <el-form-item label="经营类型" prop="businessType">
          <el-radio-group v-model="addCompanyForm.businessType">
            <el-radio v-for="(item, index) in businessTypeList" :key="'type-'+index" :label="item.type">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button @click="save(1)" size="small" type="primary">保 存</el-button>
        <el-button @click="save(2)" size="small" type="primary">保存并认证</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { BusinessTypeListEnum } from '@/enums/BusinessTypeListEnum';
import { createTenant } from '@/api/company';
import switchCompany from '@/utils/switchCompany';
export default {
  name: 'company-list',
  props: {
    maxHeight: {
      type: Number,
      default: 300,
    },
    showAdditive: {
      type: Boolean,
      default: true,
    },
    companyLists: [],
  },
  watch: {
    companyLists: {
      deep: true,
      immediate: true,
      handler () {
        let list = JSON.parse(JSON.stringify((this.companyLists)));
        this.personCompany = (list.filter(item => item.isPersonal === true))[0] || {};
        this.dataList = list.filter(item => item.isPersonal === false);
      },
    },
  },
  data () {
    return {
      personCompany: {},
      dataList: [],
      dialogVisible: false,
      statusList: [
        {
          status: '未认证',
          statusCode: '1',
          img: require('@/assets/ic_weirenzheng.svg'),
        },
        {
          status: '认证中',
          statusCode: '2',
          img: require('@/assets/ic_renzhengzhong.svg'),
        },
        {
          status: '已认证',
          statusCode: '3',
          img: require('@/assets/ic_yirenzheng.svg'),
        },
      ],
      addCompanyForm: {
        company: '',
        businessType: '',
      },
      addCompanyFormRules: {
        company: [
          { required: true, message: '请填写企业名称', trigger: 'blur' },
        ],
        businessType: [
          { required: true, message: '请选择经营类型', trigger: 'change' },
        ],
      },
      businessTypeList: BusinessTypeListEnum,
    };
  },
  methods: {
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
    filterIdentifyStatus (status) {
      let statusIcon = '';
      this.statusList.map(item => {
        if (status === item.statusCode) {
          statusIcon = item.img;
        }
        return item;
      });
      return statusIcon;
    },
    async switchCurrentCompany (item) {
      await switchCompany(item.tenantId);
      this.updateUserInfo(JSON.parse(this.$local.get('userInfo')));
      if (!this.showAdditive) {
        this.$emit('refresh', item);
      } else {
        this.$tabs.items = [];
        this.$router.push({
          path: '/workbench',
        });
        window.location.reload();
      }
    },
    async save (type) {
      this.$refs.addCompanyForm.validate().then(async valid =>{
        if (valid) {
          const { employeeMsg } = JSON.parse(this.$local.get('userInfo'));

          const list = await createTenant({
            id: employeeMsg.id,
            tenantNames: [this.addCompanyForm.company],
            businessType: this.addCompanyForm.businessType,
          });

          await switchCompany(list.body[0].tenantId);

          this.updateUserInfo(JSON.parse(this.$local.get('userInfo')));

          this.addCompanyForm.company = '';
          this.addCompanyForm.businessType = '';
          this.dialogVisible = false;
          this.$message.success('企业创建成功');

          // this.$emit('refresh');
          this.$tabs.items = [];
          this.$router.push({
            path: '/workbench',
          });
          window.location.reload();

          if (type === 2) {
            setTimeout(() => {
              this.$router.push({
                name: 'IDENTIFICATION',
              });
            }, 2000);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.xz-company-tip {
  font-size: 14px;
  font-weight: bold;
  color: #1F2733;
  padding-top: 20px;
}
.xz-company-list {
  min-height: 200px;
  overflow-y: scroll;
}
.xz-company {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  .company-name {
    display: inline-flex;
    align-items: center;
    .head {
      font-size: 14px;
      font-weight: bold;
      color: #1A66FF;
      padding: 10px 13px;
      background: #E3ECFF;
      border-radius: 4px;
      margin-right: 5px;
    }
    .name {
      font-size: 14px;
      font-weight: bold;
      >img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }
  > img {
    flex-shrink: 0;
    margin-left: 10px;
    width: 16px;
    height: 16px;
  }
}
.xz-company:hover {
  background-color: #F2F4F7;
}
.xz-company-create {
  cursor: pointer;
  color: #237FFA;
  font-weight: bold;
  padding: 20px 10px 10px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  > img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}

.create-company-tip {
  font-size: 12px;
  font-weight: 400;
  color: #929AA6;
  padding: 10px 0 20px;
}

// .create-company {
//   display: flex;
//   align-items: center;
//   line-height: normal;
//   .label {
//     padding-right: 10px;
//     flex-shrink: 0;
//   }
// }
</style>
